import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';

import { JwtService } from './jwt.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiService {
  
  constructor(
    private http: HttpClient,
    private jwtService: JwtService
  ) {}

  private formatErrors(error: any) {
    console.log(error);
    return  throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { params }).pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}, headers?: HttpHeaders): Observable<any> {
    return this.http.put(`${environment.apiUrl}${path}`, JSON.stringify(body), {headers: headers}).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}, headers?: HttpHeaders): Observable<any> {
    return this.http.post(`${environment.apiUrl}${path}`, JSON.stringify(body), {headers: headers}).pipe(catchError(this.formatErrors));
  }

  delete(path): Observable<any> {
    return this.http.delete(`${environment.apiUrl}${path}`).pipe(catchError(this.formatErrors));
  }
}
