import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { QuestionsPaginationResponse } from '../models/q-pagination-response.model';

@Injectable({
  providedIn: 'root'
})
export class UserQuestionsService {

  constructor(
    private apiService: ApiService
  ) { }

  getUserQuestions(username: string, page:number): Observable<QuestionsPaginationResponse>{
    return this.apiService.get(`/api/users/${username}/questions?page=${page}`);
  }
}
