import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Menu } from '../models/menu.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private apiService: ApiService
  ) { }

  getMenus(menubar: number): Observable<Menu[]>{
    return this.apiService.get(`/api/menubars/${menubar}/menus`);
  }

}
