import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Answer } from '../models/answer.model';
import { Observable } from 'rxjs';
import { AnswersPaginationResponse } from '../models/a-pagination-response.model';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    constructor(
        public apiService: ApiService
    ) { }

    search(data): Observable<any> {
        return this.apiService.post(`/api/search`, data);
    }
}