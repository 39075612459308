import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyInfo } from '../models/company-info.mode';
import { ApiService } from './api.service';
import { UserInfo } from '../models/user-info.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyInfoService {

  constructor(
    private apiService: ApiService
  ) { }

  getCompanyInfo(username: string): Observable<UserInfo> {
    return this.apiService.get(`/api/users/${username}`);
  }

  getCompanyFollowers(username: string): Observable<number>{
    return this.apiService.get(`/api/users/${username}/followers`);
  }

}
