import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Answer } from '../models/answer.model';
import { Observable } from 'rxjs';
import { AnswersPaginationResponse } from '../models/a-pagination-response.model';

@Injectable({
  providedIn: 'root'
})
export class UserAnswerService {

  constructor(
    private apiService: ApiService
  ) { }

  getAnswers(username: string, page:number) :Observable<AnswersPaginationResponse>{
    return this.apiService.get(`/api/users/${username}/answers?page=${page}`);
  }

}
