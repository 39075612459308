import { Component, OnInit } from '@angular/core';
import { UserService } from './core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  constructor(
    private userService: UserService
  ){
    // window.localStorage['language'] = 'en';
    this.useLanguage(window.localStorage['language']);
  }

  ngOnInit(){
    console.log('before calling populate');
    this.userService.populate();
    
  }


  useLanguage(languageCode: string) {
    var element = document.getElementById("main-body");
    if (languageCode == 'ar') {
      element.setAttribute("dir", "rtl");
    } else {
      element.removeAttribute("dir");
    } 
  }
}
