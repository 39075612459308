import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { Category } from '../models/category.model';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

    constructor(private apiService: ApiService) {}

    listCategories(): Observable<Category[]>{
      return this.apiService.get(`/api/categories`);
    }

    // To-do ad strength

}