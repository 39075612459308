import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { QuestionsPaginationResponse } from '../models/q-pagination-response.model';
import { AnswersPaginationResponse } from '../models/a-pagination-response.model';
import { MyAdsPaginationResponse } from '../models/my-ads-pagination.model';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  constructor(
    public apiService: ApiService
  ) { }

  getQuestionsPaginated(page: number): Observable<QuestionsPaginationResponse> {
    return this.apiService.get(`/api/questions?page=${page}`);
  }

  getAnswersPaginated(page: number, questionId: number): Observable<AnswersPaginationResponse> {
    return this.apiService.get(`/api/questions/${questionId}/answers?page=${page}`);
  }

  getMyAds(page: number): Observable<MyAdsPaginationResponse> {
    // return this.apiService.get(`/api/secured/posts/filter?page=${page}`);
    return this.apiService.get(`/api/secured/users/posts?page=${page}`);
  }
}
