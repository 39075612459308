import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Activity } from '../models/activity.model';
import { Observable } from 'rxjs';
import { PaginationResponse } from '../models/pagination.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(
    public apiService: ApiService
  ) { }

  getActivities(page:number):Observable<PaginationResponse<Activity>>{
    return this.apiService.get(`/api/secured/user/activities?page=${page}`);
  }

  getNotifications(page:number):Observable<PaginationResponse<Activity>>{
    return this.apiService.get(`/api/secured/user/activities/foruser?page=${page}`);
  }
}
