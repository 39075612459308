import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit } from '@angular/core';
import { UserService } from 'app/core/services';
import { ActivityService } from 'app/core/services/activity.service';
import { PaginationResponse } from 'app/core/models/pagination.model';
import { Activity } from 'app/core/models/activity.model';
import { TranslateService } from '@ngx-translate/core';

declare var $:any;
//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    // icon: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [{
        path: 'dashboard',
        title: 'menu.dashboard',
        type: 'link',
        icontype: 'fa fa-dashboard'
    },    
    {
        path: 'myads',
        title: 'menu.myads',
        type: 'link',
        icontype: 'fa fa-briefcase'
    },
    {
        path: 'favads',
        title: 'menu.favoritedAds',
        type: 'link',
        icontype: 'fa fa-star-o'
    },
    {
        path: 'limitedoffers',
        title: 'menu.limitedOffers',
        type: 'link',
        icontype: 'fa fa-ticket'
    },
    {
        path: 'winners',
        title: 'menu.winners',
        type: 'link',
        icontype: 'fa fa-trophy'
    },
    {
        path: 'clients',
        title: 'menu.favoritesClients',
        type: 'link',
        icontype: 'fa fa-user-circle-o'
    },
    {
        path: 'newsletter',
        title: 'menu.newsletter',
        type: 'link',
        icontype: 'fa fa-newspaper-o'
    },
    {
        path: 'settings',
        title: 'menu.settings',
        type: 'link',
        icontype: 'fa fa-gears'
    },
    {
        path: 'notificationsettings',
        title: 'menu.notificationSettings',
        type: 'link',
        icontype: 'fa fa-bullhorn'
    },
    {
        path: 'recentactivities',
        title: 'menu.recentActivites',
        type: 'link',
        icontype: 'fa fa-bell-o'
    }
    // {
    //     path: 'place-ad',
    //     title: 'Placing Ads',
    //     type: 'link',
    //     icontype: 'business_center'
    // },
 
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})
export class SidebarComponent {
    public page:number = 0;
    public notifications: PaginationResponse<Activity>;

    constructor(
        private userService: UserService,
        private activityService: ActivityService,
        private translate: TranslateService
    ) {
        translate.setDefaultLang(window.localStorage['language']? window.localStorage['language'] : 'en');
    }

    public menuItems: any[];
    isNotMobileMenu(){
        if($(window).width() > 991){
            return false;
        }
        return true;
    }

    ngOnInit() {
        var isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;
        this.menuItems = ROUTES.filter(menuItem => menuItem);

        isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

        if (isWindows){
           // if we are on windows OS we activate the perfectScrollbar function
           $('.sidebar .sidebar-wrapper, .main-panel').perfectScrollbar();
           $('html').addClass('perfect-scrollbar-on');
       } else {
           $('html').addClass('perfect-scrollbar-off');
       }

       this.getNotifications(this.page);
    }
    ngAfterViewInit(){
        var $sidebarParent = $('.sidebar .nav > li.active .collapse li.active > a').parent().parent().parent();

        var collapseId = $sidebarParent.siblings('a').attr("href");

        $(collapseId).collapse("show");
    }

    get user(){
        return this.userService.getCurrentUser();
    }

    getNotifications(page:number){
        this.activityService.getNotifications(page).subscribe((data:PaginationResponse<Activity>)=>{
            this.notifications = data;
        })
    }
}
