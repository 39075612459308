import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserInfo } from '../models/user-info.model';
import { ApiService } from './api.service';
import { environment } from 'environments/environment';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { UserSocial } from '../models/user-social.model';
import { UserFollow } from '../models/user-follow.model';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  
  constructor(
    private apiService: ApiService,
    private http: HttpClient
  ) { }

  getUserInfo(username: string): Observable<UserInfo> {
    return this.apiService.get(`/api/users/${username}`);
  }

  getUserFollowers(username: string): Observable<number>{
    return this.apiService.get(`/api/users/${username}/followers`);
  }

  getUserFollowing(username: string): Observable<number>{
    return this.apiService.get(`/api/users/${username}/followingcount`);
  }

  isFollowed(username: string):Observable<boolean>{
    return this.apiService.get(`/api/secured/users/${username}/isfollowed`);
  }

  follow(username: string): Observable<UserFollow>{
    return this.apiService.post(`/api/secured/users/follow` , {'follows' : username});
  }

  unfollow(username: string): Observable<any>{
    return this.apiService.delete(`/api/secured/users/unfollow/${username}`);
  }

  getMyInfo(): Observable<UserInfo> {
    return this.apiService.get(`/api/secured/users/myinfo`);
  }

  updateUserInfo(data): Observable<any>{
    return this.http.post(`${environment.apiUrl}/api/secured/users`, data);
  }

  updatePassword(data: any): Observable<any>{
    return this.apiService.post(`/api/secured/users/update-pass`, data);
  }

  updateSocialLinks(data: any) {
    return this.apiService.post(`/api/secured/users/update-social`, data);
  }

  getMySocialLinks(): Observable<UserSocial>{
    return this.apiService.get(`/api/secured/users/mylinks`);
  }
}

