import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';

import { SidebarModule } from './secured/sidebar/sidebar.module';
import { FooterModule } from './secured/shared/footer/footer.module';
import { NavbarModule } from './secured/shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';
import { PublicLayoutComponent } from './layouts/public/public-layout.component';
//import { PlacingAdsComponent } from './auth/placing-ads/placing-ads.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AgmCoreModule } from '@agm/core';
import { PlacingAdsComponent } from './secured/placing-ads/placing-ads.component';
import { UserService } from './core/services';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CoreModule } from './core/core.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MasonryModule, Masonry } from '@thisissoon/angular-masonry';
import { LocationStrategy, Location, PathLocationStrategy } from '@angular/common';

import { MetafrenzyModule, MetafrenzyGuard } from 'ngx-metafrenzy';


import {
  LocalizeRouterModule, LocalizeParser, ManualParserLoader, CacheMechanism,
  LocalizeRouterSettings
} from '@gilsdav/ngx-translate-router';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
const masonryProviders = [
  { provide: Masonry, useFactory: () => window['Masonry'] },
];

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true
    }),

    HttpModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    CoreModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAJA72G0mw2JdeFKMgIxKk00s7w5EeYsxA',
      libraries: ['places']
    }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // LocalizeRouterModule.forRoot(AppRoutes, {
    //     parser: {
    //       provide: LocalizeParser,
    //       useFactory: (translate, location, settings, http) =>
    //           new LocalizeRouterHttpLoader(translate, location, settings, http),
    //       deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
    //     }
    //   }),
    BsDropdownModule.forRoot(),
    SlickCarouselModule,
    MasonryModule.forRoot(masonryProviders)

  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    PublicLayoutComponent,
    AuthLayoutComponent,
  ],
  exports: [TranslateModule],
  providers: [
    UserService,
    Location, { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }