import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { PaginationService } from './services/pagination.service';


import {
    AuthService,
    NotificationService,
    JwtService,
    ApiService,
    HomeService,
    UserService,
    AdService,
    NoAuthGuard,
    UserInfoService,
    UserSocialService,
    CompanyInfoService,
    UserQuestionsService,
    UserAnswerService,
    SearchService,
    WebSocketAPIService
} from './services';

import { ShowAuthedDirective } from './directives/show-authed.directive';
import { TruncateTextPipe } from './pipes/truncate-text.pipe';



@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    AuthService,
    NotificationService,
    JwtService,
    ApiService,
    HomeService,
    UserService,
    AdService,
    NoAuthGuard,
    UserInfoService,
    UserSocialService,
    CompanyInfoService,
    UserQuestionsService,
    UserAnswerService,
    PaginationService,
    SearchService,
    WebSocketAPIService,
    TruncateTextPipe
  ],
  declarations:[
    ShowAuthedDirective,
    TruncateTextPipe
  ],
  exports:[
    ShowAuthedDirective
  ]
})
export class CoreModule { }
