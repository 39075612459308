import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {}

  register(data) {
    console.log(data);
    return this.apiService.post('/signup', data);
  };

  sendEmailAgain(data) {
    return this.apiService.post('/send-again', data);
  }

  forgetPassword(data) {
    return this.apiService.post('/forgetPassword', data);
  }

  confirmForgetPass(code) {
    return this.apiService.post(`/confirmForgetPassword`, {'enc': code});
  }

  resetPassword(code, data) {
    data['enc'] = code;
    return this.apiService.post(`/setNewPassword`, data);
  }
}
