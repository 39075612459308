import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'environments/environment';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class WebSocketAPIService {

    webSocketEndPoint: string = '';//`${environment.socketUrl}/ws`;
    topic: string = "/cjo/notifications";
    stompClient: any;

    constructor(
        private userService: UserService
    ) {}

    _connect() {
        console.log("Initialize WebSocket Connection");
        let ws = new SockJS(this.webSocketEndPoint);
        this.stompClient = Stomp.over(ws);
        const _this = this;
        _this.stompClient.connect({'Authorization': `Bearer ${window.localStorage['jwtToken']}`}, function (frame) {
            _this.stompClient.subscribe(_this.topic, function (sdkEvent) {
                _this.onMessageReceived(sdkEvent);
            });
            //_this.stompClient.reconnect_delay = 2000;
        }, this.errorCallBack);
    };

    _disconnect() {
        if (this.stompClient !== null) {
            this.stompClient.disconnect();
        }
        console.log("Disconnected");
    }

    // on error, schedule a reconnection attempt
    errorCallBack(error) {
        const _this = this;
        console.log("errorCallBack -> " + error)
        setTimeout(() => {
            _this._connect();
        }, 5000);
    }

    /**
     * Send message to sever via web socket
     * @param {*} message 
     */
    _send(message) {
        console.log("calling logout api via web socket");
        this.stompClient.send("/send/message", {}, JSON.stringify(message));
    }

    onMessageReceived(message) {
        console.log("Message Recieved from Server :: " + message);
    }

}