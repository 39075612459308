// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: 'https://cjomw.herokuapp.com',
  // apiUrl: 'http://52.68.21.43:8090',
  // apiUrl: 'http://localhost:8090',
  socketUrl: 'http://localhost:8090',
  awsUrl: 'http://cdn-clickjo.s3-website-ap-northeast-1.amazonaws.com'
};
