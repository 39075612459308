import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { UserSocial } from '../models/user-social.model';


@Injectable({
  providedIn: 'root'
})
export class UserSocialService {

  constructor(
    private apiService: ApiService
  ) { }

  getUserSocialLinks(username: string): Observable<UserSocial>{
    return this.apiService.get(`/api/users/${username}/social`);
  }

}
