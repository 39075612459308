import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { PublicLayoutComponent } from './layouts/public/public-layout.component';

export const AppRoutes: Routes = [
	{
		path: '',
		component: PublicLayoutComponent,
		children: [
			{ path: '', loadChildren: './public/public.module#PublicModule' }
		],

	},
	{
		path: 'auth',
		component: AuthLayoutComponent,
		children: [
			{ path: '', loadChildren: './auth/auth.module#AuthModule' }
		]
	},
	{
		path: 'secured',
		component: AdminLayoutComponent,
		children: [
			{ path: '', loadChildren: './secured/secured.module#SecuredModule' }
		]

	},
	/* {
		path: '',
		redirectTo: ''
	} */
		//canActivate: [AuthGuard],
	{
		path: '**',
		redirectTo: ''
	}
];
