import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ad } from '../models/ad.model';
import { ApiService } from './api.service';
import { AdStrength } from '../models/adstrength.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { PostReport } from '../models/ad-report.model';

@Injectable({
  providedIn: 'root'
})
export class AdService {

  constructor(
    private apiService: ApiService,
    private http: HttpClient
  ) { }

  listBoxesByCategory(categorySlug: string): Observable<Ad[]> {
    return this.apiService.get(`/api/posts/categories/${categorySlug}/boxes`);
  }

  listAdStrength(): Observable<AdStrength[]> {
    return this.apiService.get(`/api/secured/postweights`);
  }

  makeItHot(postId: number) {
    return this.apiService.get(`/api/secured/posts/${postId}/hot`);
  }

  postAdStrength(data, id): Observable<any> {
    if (id > 0) {
      return this.http.put(`${environment.apiUrl}/api/secured/posts`, data);
    } else {
      return this.http.post(`${environment.apiUrl}/api/secured/posts`, data);
    }
  }

  listAllClassifiedByCategorySlug(slug: string): Observable<Ad[]> {
    return this.apiService.get(`/api/posts/categories/${slug}/classified`);
  }

  latestAds() {
    return this.apiService.get(`/api/posts/latest`);
  }

  hotAds() {
    return this.apiService.get(`/api/posts/hot`);
  }

  hotAdsForCompany(username: string): Observable<Ad[]> {
    return this.apiService.get(`/api/posts/hot/${username}`);
  }

  topAds() {
    return this.apiService.get(`/api/posts/top`);
  }
  callStatistic(id: number, type: number): Observable<string> {
    return this.apiService.post(`/api/statistics`, { 'postId': id, 'type': type });
  }

  reportAd(id: number, reportType: number, reason: string): Observable<PostReport> {
    return this.apiService.post(`/api/secured/postreports`, { 'postId': id, 'reportType': reportType, 'reason': reason });
  }

  getAdDetails(id: number): Observable<Ad> {
    return this.apiService.get(`/api/posts/${id}`);
  }

  getStatus() {

    return this.apiService.get("/api/statuss/")
  }

  postAdFilter(data: HttpParams): Observable<any> {
    return this.apiService.get(`${environment.apiUrl}/api/secured/posts/filter`, data);
  }
}