import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { ApiService, HomeService, UserService, MenuService } from '../../core/services';
import { PublicService } from '../../core/services/public.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Menu } from 'app/core/models/menu.model';
declare const $: any;
@Component({
    selector: 'app-layout',
    templateUrl: './public-layout.component.html',
    styleUrls: ['./public-layout.component.scss'],
})
export class PublicLayoutComponent implements OnInit, AfterViewInit {
    ngAfterViewInit(): void {
        $(document).on('mouseover', ".dropdown-toggle", function () {
            $(`[aria-expanded="true"]`).click();
        })
        $(document).on('mousemove', ".dropdown-toggle", function () {
            $(this).blur();
        })
    }

    searchValue: string;

    mainMenu: Menu[];
    footerMenu: Menu[];

    status: boolean = false;
    togglemenuClass() {
        this.status = !this.status;
    }

    constructor(
        private router: Router,
        private translate: TranslateService,
        private element: ElementRef,
        private homeService: PublicService,
        private userService: UserService,
        private menuService: MenuService
    ) {
        translate.setDefaultLang(window.localStorage['language'] ? window.localStorage['language'] : 'en');
    }

    onActivate(event) {
        window.scroll(0, 0);
    }

    useLanguage(languageCode: string) {
        var element = document.getElementById("main-body");

        window.localStorage['language'] = languageCode;

        if (languageCode == 'en') {
            element.removeAttribute("dir");
        } else if (languageCode == 'ar') {
            element.setAttribute("dir", "rtl");
        }

        this.translate.use(languageCode);
    }

    ngOnInit() {
        this.loadMenu();
        this.translate.onLangChange.subscribe(() => {
            this.loadMenu();
        });

    }

    loadMenu(){
        this.menuService.getMenus(2).subscribe(data => {
            //this.mainMenu = data
            var level1Menus = data.filter(m => m.parent === null);
            level1Menus.map(m => {
                m['children'] = data.filter(d => d.parent && d.parent.menuId === m.menuId);
                return m;
            });

            this.mainMenu = level1Menus;
        });
    }

    get loggedInUser() {
        return this.userService.getCurrentUser();
    }
    get isLoggedIn() {
        return this.userService.isAuthenticated.pipe(take(1));
    }
    logout() {
        this.userService.logout();
    }

    switchLanguage(language: string) {
        this.useLanguage(language);
        // this.translate.use(language);
    }

    get language() {
        return window.localStorage['language'];
    }

    search(event) {
        let searchKey = event.target.value;
        this.searchValue = searchKey;
        if (searchKey) {
            this.homeService.searchListener.next(this.searchValue);
            //this.router.navigate(['/search'], {queryParams: {key: searchKey}});
            this.router.navigateByUrl('/search');
        }
    }

    searchField(event: HTMLInputElement) {
        let searchKey = event.value;
        this.searchValue = searchKey;
        if (searchKey) {
            this.homeService.searchListener.next(this.searchValue);
            //this.router.navigate(['/search'], {queryParams: {key: searchKey}});
            this.router.navigateByUrl('/search');
        }
    }
    hideOtherDropDown() {

    }

}


